import * as React from "react";
import JourneyImage from "../../components/JourneyImage";
import JourneyPageLayout, {
  CHAPTERS,
} from "../../components/JourneyPageLayout";
import chatMessengerInVisualBasic6Image from "../../images/journey/chat_messenger_in_vb6.jpg";
import mIRCImage from "../../images/journey/mirc.jpg";
import printerBirthdayPresentImage from "../../images/journey/printer_birthday_present.jpg";
import programmingVisualBasic6Image from "../../images/journey/programming_visual_basic6.jpg";
import ogImage from "../../images/og/journey-hello-world.jpg";

const JourneyHelloWorldPage = () => {
  return (
    <JourneyPageLayout
      pageTitle="Matthew Goslett - How I Became A Programmer"
      metaDescription="Matthew Goslett shares how he was introduced to computer programming at the age of 12 years old."
      og={{ image: ogImage }}
      chapter={CHAPTERS.HELLO_WORLD}
    >
      <p>
        I was first introduced to computers at my school, Herzlia Constantia,
        around 1996. As a bit of a late bloomer relative to my friends, I
        remember sitting in the computer lab with Netscape Navigator 3.0 open,
        completely lost and uncertain as to what the internet was or how it
        worked.
      </p>
      <p>
        It didn't take long for me to catch up and develop what would soon be an
        unhealthy and obsessive relationship with computers. We bought our first
        shared family computer (Pentium II) around 1998 when I was 10 years old.
        We used a dial-up modem back then, but access to the internet was
        limited due to the prohibitive cost of telecoms here in South Africa. I
        managed to convince my parents to sign up for Telkom's "R7 Call More"
        deal. This allowed us to connect from 7 at night to 7 in the morning
        during the week, and from Friday evening to Monday morning, for the cost
        of a R7 call. The single phone line we had was permanently engaged.
      </p>
      <JourneyImage image={printerBirthdayPresentImage} />
      <p>
        I was extremely shy and introverted growing up, and as such, a lot of my
        time was spent entrenched in the digital world. I was first introduced
        to the concept of a virtual community via forums and mailing lists. I
        later discovered Internet Relay Chat (IRC), a chat protocol which was
        the de facto standard in the 90's and early 2000's. I assumed the
        nickname fmaxwell41 (named after my dad's band) and became a regular on
        ZAnet, South Africa's largest IRC network at the time.
      </p>
      <p>
        The earliest exposure I had to programming (or scripting) was via mIRC,
        the popular Microsoft Windows client for IRC. mIRC scripting language
        (mSL) is a procedural language embedded in the client which enables one
        to write utility functions, bots, automated triggers, and custom
        commands focused on enhancing the chat experience. This is its primary
        intention and most common use-case; however, being a high level
        scripting language, it can also be used to create basic games and
        applications.
      </p>
      <JourneyImage image={mIRCImage} />
      <p>
        I was intrigued by this scripting language but more importantly by the
        concept of IRC - how it worked, how structured information could travel
        from A to B across the world, and how an application could handle such
        information and visualise it to an end user. I started looking at
        RFC1459 - the original protocol specification for IRC. This led me to
        learning about sockets, networking, TCP/UDP, and ports - in a nutshell,
        how computers are able to talk to each other over a network (or the
        internet).
      </p>
      <p>
        The first real-world application I wrote was an IRCd (Internet Relay
        Chat daemon), a piece of server software which implements the IRC
        protocol. I wrote the application in mSL on top of mIRC, which was all I
        knew at the time, but this opened the door for me to the world of
        programming and ultimately led to me wanting to learn more.
      </p>
      <JourneyImage image={programmingVisualBasic6Image} />
      <p>
        Visual Basic 6 (VB6) was the first "real" programming language I
        experimented with. I was introduced to it through the brother of a close
        friend, who gave me a book (Programming Visual Basic 6.0) to read. At
        the age of 14, I created and open-sourced my first VB6 application,
        Multi-Chat Server and Client. It was a very rudimentary clone of IRC
        with server and client components, allowing people to chat in virtual
        rooms across the internet. I wrote a few more applications in VB, all
        themed around networking and gaming (Counter-Strike), my two biggest
        obsessions at the time.
      </p>
      <JourneyImage image={chatMessengerInVisualBasic6Image} />
      <p>
        I later expanded into web application development and transitioned away
        from desktop applications. I first picked up the fundamental web
        languages, HTML, CSS, and JS, and built a few static hobby websites. I
        then learnt ASP classic and started to build dynamic website components
        - guestbooks, signup/login forms, feedback pages, etc (some truly
        horrific code of mine). It was at this time when I first discovered
        databases and started playing around with the MySQL database server.
        This was all done on the Microsoft Windows platform (IIS + ASP + MySQL),
        which was all I had been exposed to up to then. I'd heard of OS/2 and
        *nix through a family friend who worked in the industry but had never
        actually seen these operating systems.
      </p>
      <p>
        The first time I saw Linux was at a LAN (Local Area Network) party. A
        LAN party is where computer enthusiasts get together and typically play
        multiplayer games against each other and share media. I was given a copy
        of RedHat Linux and immediately started playing around. The learning
        curve was steep for me coming from Microsoft Windows into such a
        low-level and less visual/user-friendly operating system. I recall being
        particularly frustrated with the simplest of things, such as getting
        file sharing via Samba to work without glitches.
      </p>
      <p>
        I persevered, however, and spent a lot of time reading manuals, how-to
        server guides, and online mailing lists. I was introduced to this thing
        called a LAMP stack - a server stack which is named as an acronym for
        Linux, Apache, MySQL, and PHP - a programming language which was new to
        me. I dived into PHP 4 and began to port some of my legacy applications
        from ASP over to PHP. I furthered my knowledge and gained experience by
        dissecting the (at the time) more popular and larger code bases such as
        PHP-Nuke, phpBB, and vBulletin. This was in the days of spaghetti code,
        which included globals, spattered mysql_connects, god classes, and PHP
        files embedded within HTML (it was all pretty disgusting).
      </p>
      <p>
        I got my first contract job when I was 16 via a friend in the gaming
        community, Arman. His mother was a Prof. at the National Institute for
        Occupational Health's (NIOH) Department of Toxicology. They were running
        a local conference and needed a website for delegates to register, pay,
        and submit abstract papers. It was a rather arbitrary job for me, but I
        delivered; and I wound up doing 2 subsequent conference websites for
        them, too. This foot in the door landed me subsequent jobs in the local
        scene, mostly in the gaming community - The Professional Cyber Gaming
        League (PCGL) and Arena77, an online gaming platform launched by New Age
        Gaming (NAG) magazine's Len Nery. I developed a close bond with Arman,
        despite the fact that we'd only ever known each other over the internet,
        and we partnered up to start our own computer and gaming e-commerce
        store - LiquidHive. We (2 kids) even somehow managed to get the
        distribution rights for SteelSeries gaming peripherals. We imported the
        products, took payment online, and shipped them after school hours. The
        "business" did alright, but we were both young, and eventually we moved
        on from the gaming scene and decided to shut it down.
      </p>
    </JourneyPageLayout>
  );
};

export default JourneyHelloWorldPage;
